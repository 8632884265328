<template>
  <div class="field">
    <field-label v-if="label" v-bind="{ required, requiredClass, requiredText, hint, hintIcon, hintClass, description }">
      <slot/>
    </field-label>
    <p :class="['field-description', descriptionClass]" v-if="description">{{ description }}</p>
    <div class="field is-marginless" :class="{ 'has-addons': $slots.left || $slots.right }">
      <div class="control" v-if="$slots.left"><slot name="left"/></div>
      <div class="control is-expanded" :class="controlClass">
        <dropdown-wrapper v-bind="{ disabled, position }" class="selector-wrapper" ref="dropdown">
          <template slot="trigger" slot-scope="{ isActive, isFlipped }">
            <template v-if="searchable && !multiple">
              <input
                v-bind="{ disabled, autofocus, autocomplete, role }"
                autocapitalize="off"
                v-model="search"
                type="text"
                @focus="$event.target.select()"
                @input="emitSearch"
                @keydown="handler"
                class="selector input"
                :placeholder="promptLabel"
                :class="[{ 'is-active': isActive, 'is-flipped': isFlipped }, inputClass]"/>
              <icon v-if="leftIcon" :class="['is-left', iconClass]" :pack="leftIconPack" :type="leftIconType" :icon="leftIcon"/>
              <icon v-if="rightIcon && searchable" :class="['is-right', iconClass]" :pack="rightIconPack" :type="rightIconType" :icon="rightIcon"/>
            </template>
            <div v-else-if="!multiple"
              class="selector input select"
              v-bind="{ disabled }"
              :class="[{ 'is-active': isActive, 'is-flipped': isFlipped }, inputClass]">
              <span class="data-label">{{ selectedLabel || promptLabel }}</span>
              <icon v-if="leftIcon" :class="['is-left', iconClass]" :pack="leftIconPack" :type="leftIconType" :icon="leftIcon"/>
              <icon v-if="rightIcon && searchable" :class="['is-right', iconClass]" :pack="rightIconPack" :type="rightIconType" :icon="rightIcon"/>
            </div>
            <div v-else
              @click="searchable ? $refs.multiSearch.focus() : null"
              class="selector input select has-children"
              v-bind="{ disabled }"
              :class="[{ 'is-active': isActive, 'is-flipped': isFlipped, searchable }, inputClass]">
              <div class="field is-grouped is-grouped-multiline">
                <div class="control" v-for="item in selected" :key="item[valueKey]">
                  <div class="tags has-addons">
                    <span @click.stop="deleteButtons ? $refs.dropdown.toggle() : deleteItem(item)" :class="['tag', tagClass, {'is-danger': !isSelectable(item)}]">{{ item[labelKey] }}</span>
                    <a v-if="deleteButtons" class="tag is-delete" :class="deleteButtonClass" @click.stop="deleteItem(item)"></a>
                  </div>
                </div>
                <div class="control">
                  <input
                    v-autowidth
                    role="presentation"
                    autocapitalize="off"
                    v-bind="{ autofocus, autocomplete, role }"
                    ref="multiSearch"
                    v-if="searchable"
                    v-model="search"
                    type="text"
                    @focus="$event.target.select()"
                    @blur="blurSearch"
                    @keydown="handler"
                    :placeholder="searchLabel"/>
                </div>
                <icon v-if="leftIcon" :class="['is-left', iconClass]" :pack="leftIconPack" :type="leftIconType" :icon="leftIcon"/>
                <icon v-if="rightIcon && searchable" :class="['is-right', iconClass]" :pack="rightIconPack" :type="rightIconType" :icon="rightIcon"/>
                <span
                  v-if="multiple && !selected.length && !searchable"
                  class="has-text-grey">
                  {{ promptLabel }}
                </span>
              </div>
            </div>
          </template>
          <dropdown-item
            tabindex="0"
            v-for="item in computedItems" :key="item[valueKey]"
            @click="handleSelection(item)"
            :class="{ 'is-active': item[valueKey] === selected, 'is-highlighted': multiple && isSelected(item) }">
            <slot v-if="$scopedSlots.item" name="item" v-bind="{ item }"/>
            <span v-else class="item-label">{{ item[labelKey] }}</span>
          </dropdown-item>
          <dropdown-item v-if="!computedItems.length">{{ emptyText }}</dropdown-item>
        </dropdown-wrapper>
      </div>
      <div class="control" v-if="$slots.right"><slot name="right"/></div>
    </div>
    <field-error v-if="hasError" v-bind="{ error, errorClass, errorSymbol }"/>
  </div>
</template>

<script>
import * as props from '@/mixins/props'
import * as computed from '@/mixins/computed'
import { slug } from '@/utils/string'
import Search from '@/utils/search'
import find from 'lodash/find'
import map from 'lodash/map'
import differenceBy from 'lodash/differenceBy'

export default {

  mixins: [
    props.autocomplete,
    props.autofocus,
    props.classes,
    props.describable,
    props.disabled,
    props.error,
    props.fullWidth,
    props.hintable,
    props.iconable,
    props.items,
    props.label,
    props.required,
    props.role,
    props.working,

    computed.hasError
  ],

  props: {
    value: {
      default: '',
    },
    position: {
      type: String,
      default: 'bottom-start'
    },
    promptLabel: {
      type: String,
      default: 'Select',
    },
    searchLabel: {
      type: String,
      default: '',
    },
    emptyText: {
      type: String,
      default: 'No items to display.'
    },
    searchable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    deleteButtons: {
      type: Boolean,
      default: true,
    },
    showSelected: {
      type: Boolean,
      default: false,
    },
    tagClass: {
      type: Array | String | Object,
      default: 'is-light'
    },
    deleteButtonClass: {
      type: Array | String | Object,
    },
    leftIconPack: {
      default: 'fa',
    },
    rightIconPack: {
      default: 'fa',
    }
  },

  data() {
    return {
      selected: '',
      search: ''
    }
  },

  computed: {
    controlClass() {
      return [{
        'has-icons-left': this.leftIcon,
        'has-icons-right': this.rightIcon,
        'is-loading': this.working && this.searchable,
      }, this.classes]
    },
    inputClass() {
      return [{
        [this.errorClass]: this.hasError,
        'is-loading': this.working,
      }, this.classes]
    },
    computedItems() {
      let items = map(this.items, (item, index) => {
        if (!item[this.valueKey]) {
          item[this.valueKey] = this.slugValues
            ? slug(item[this.labelKey])
            : index + 1
        }
        return item
      })

      if (!this.multiple && !(this.searchable || this.search === this.selectedLabel)) {
        return items
      }

      let searcher = new Search(items, [this.labelKey])
      let results = this.searchable ? searcher.search(this.search) : items

      let output = results || items
      return this.multiple && !this.showSelected
        ? differenceBy(output, this.selected, this.valueKey)
        : output
    },
    selectedLabel() {
      let item = find(this.items, item => item[this.valueKey] == this.selected)
      return item ? item[this.labelKey] : ''
    }
  },

  methods: {
    emitSelected() {
      this.$emit('input', this.selected)
    },
    handler(event) {
      let { dropdown } = this.$refs
      if ([38, 40].includes(event.keyCode) && dropdown.isActive) {
        dropdown.$el.dispatchEvent(new KeyboardEvent('keydown', { key: 'tab' }))
        return
      }

      if (event.keyCode === 13) {
        // return
        if (!this.computedItems.length) return
        this.handleSelection(this.computedItems[0])
        dropdown.isActive = false
      } else if (!dropdown.isActive) {
        dropdown.isActive = true
      }
    },
    blurSearch() {
      setTimeout(() => this.search = '', 100)
    },
    emitSearch(event) {
      this.$emit('search', event.target.value)
    },
    handleSelection(item) {
      if (this.multiple) {
        this.isSelected(item)
          ? this.deleteItem(item)
          : this.selected.push(item)
      } else {
        this.search = item[this.labelKey]
        this.selected = item[this.valueKey]
      }
      this.emitSelected()
    },
    isSelectable(item) {
      return this.items.find(
        _item => _item[this.valueKey] === item[this.valueKey]
      )
    },
    isSelected(item) {
      if (Array.isArray(this.selected)) {
        return this.selected.find(
        selectedItem => selectedItem[this.valueKey] === item[this.valueKey]
        )
      } else {
        return this.selected === item[this.valueKey]
      }
    },
    deleteItem(item) {
      let value = item[this.valueKey]
      this.selected.splice(this.selected.findIndex(
        item => item[this.valueKey] === value
      ), 1)
      this.emitSelected()
    }
  },

  watch: {
    selected(value) {
      this.search = this.selectedLabel
    },
    value: {
      immediate: true,
      handler(value) {
        this.selected = value
      }
    },
    items() {
      this.$refs.dropdown.popper.update()
    }
  }
}
</script>
